<template>
  <section class="aboutus">
    <p><b>WhenSpeak</b></p>
    <div class="aboutus__title" v-html="$t('About.text')"></div>

    <form @submit="formSendMessage">
      <div v-show="!nameHidden" class="input-div">
        <input
          v-model="formName"
          class="reg-form_item"
          name="fio"
          type="text"
          :placeholder="$t('About.name')"
          required="required"
        />
      </div>
      <div class="input-div">
        <input
          v-model="formPhone"
          class="reg-form_item"
          name="phone"
          type="text"
          :placeholder="$t('About.phone')"
          required="required"
        />
      </div>
      <div v-show="!emailHidden" class="input-div">
        <input
          v-model="formEmail"
          class="reg-form_item"
          name="email"
          type="text"
          placeholder="E-mail"
          required="required"
        />
      </div>
      <div class="input-div">
        <textarea
          v-model="formMessage"
          class="reg-form_item"
          name="message"
          :placeholder="$t('About.message')"
          required="required"
        ></textarea>
      </div>
      <ws-button style="margin-bottom:46px;" color="primary" lg type="submit">
        {{ $t("common.send") }}
      </ws-button>
      <div v-show="infoMessage" class="message" :class="{ error: error }">
        {{ infoMessage }}
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { auth } from "@/store/modules/store.namespaces";

import WsButton from "@/components/base/WsButton";

const MESSAGE_TIMEOUT = 3000;

export default {
  name: "About",
  components: {
    WsButton,
  },
  data() {
    return {
      formName: "",
      nameHidden: false,
      formPhone: "",
      formEmail: "",
      emailHidden: false,
      formMessage: "",
      infoMessage: "",
      error: false,
    };
  },
  computed: {
    ...mapState(auth, ["user"]),
  },
  created() {
    this.checkFields();
  },
  methods: {
    showMessage(message, error) {
      this.infoMessage = message;
      this.error = error;
      setTimeout(() => {
        this.infoMessage = "";
        this.error = false;
      }, MESSAGE_TIMEOUT);
    },
    checkFields() {
      this.formName = (this.user && this.user.fields && this.user.fields.fio) || "";
      this.formEmail = (this.user && this.user.fields && this.user.fields.email) || "";
      if (this.formName) {
        this.nameHidden = true;
      }
      if (this.formEmail) {
        this.emailHidden = true;
      }
    },
    formSendMessage(e) {
      e.preventDefault();

      const formData = new FormData();

      formData.append("form", "продукт разовый");
      formData.append("full_name01", this.formName);
      formData.append("phone", this.formPhone);
      formData.append("email01", this.formEmail);
      formData.append("text", this.formMessage);
      formData.append("entrypoint", "wowhr");

      axios
        .post("https://whenspeak.ru/code/mail.php", formData)
        .then(resp => {
          console.log(resp);
          if (!resp.status) {
            this.showMessage(this.$t("common.msgSendError"), true);
            return;
          }
          this.showMessage(this.$t("common.msgSendSucc"), false);
          this.formName = "";
          this.formMessage = "";
          this.formPhone = "";
          this.formEmail = "";
          this.checkFields();
        })
        .catch(() => {
          this.showMessage(this.$t("common.msgSendError"), true);
        });
    },
  },
};
</script>

<style scoped lang="less">
.aboutus {
  text-align: center;
  .button.filled {
    background: var(--base-color);
    color: var(--base-button-active-color);
  }
  p {
    font-size: 18px;
  }
  form {
    width: 300px;
    max-width: 90%;
    margin: 0 auto;
    position: relative;
    .message {
      position: absolute;
      top: calc(100%);
      padding-top: 15px;
      width: 100%;
      text-align: center;
      .error {
        color: var(--negative-color);
      }
    }
    .button.big {
      width: 100%;
    }
  }
  .input-div {
    margin-bottom: 20px;
    input[type="text"] {
      border: none;
      width: 100%;
      padding: 10px 2px;
      font-size: 16px;
      line-height: normal;
      border-bottom: 1px solid #414141;
      background: none;
      color: inherit;
    }
    textarea {
      margin-top: 20px;
      padding: 10px 2px;
      border: 1px solid #414141;
      background: none;
      color: inherit;
      height: 150px;
      border-radius: 3px;
      width: 100%;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .pre-formatted {
    white-space: pre;
  }
  &__title {
    max-width: 1024px;
    margin: 0 auto;
    padding: 0 10px;
    white-space: pre-line;
  }
}
</style>
